export const LETTERS = "abcdefghijklmnopqrstuvwxyz"
export const LETTERS_M = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]
export const STAFF = [
    {
        name: "J0T0S0",
        bio: "CEO",
    },
    {
        name: "Dero",
        bio: "Designer",
    },
    {
        name: "Kairos",
        bio: "Developer",
    },
    {
        name: "Blocker",
        bio: "Tester",
    },
]
